import { Controller } from "@hotwired/stimulus";

import Dropzone from "dropzone";
import { BlobRecord } from "@rails/activestorage/src/blob_record";
import { FileChecksum } from "@rails/activestorage/src/file_checksum";

Dropzone.autoDiscover = false;

export default class extends Controller {
  connect() {
    this.maybe_connect();
    this.add_event_to_parent_modal(this.element);
  }

  add_event_to_parent_modal(element) {
    if (element.classList.contains("modal")) {
      element.addEventListener("shown.bs.modal", (_event) => {
        this.maybe_connect();
      });
    }

    if (element.parentElement) {
      this.add_event_to_parent_modal(element.parentElement);
    }
  }

  maybe_connect() {
    if (
      this.element.offsetParent != document.body &&
      this.element.offsetParent &&
      !this.element.classList.contains("converted")
    ) {
      this.do_connect();
    }
  }

  do_connect() {
    const root = this.element;
    this.element.classList.add("converted");
    const fileInput = root.querySelector('input[type="file"]');
    const dropzoneContainer = root.querySelector(".dropzone");

    let dropzone = new Dropzone(dropzoneContainer, {
      url: (files) => files[0].blobRecord.directUploadData.url,
      method: "put",
      uploadMultiple: false,
      chunking: false,
      addRemoveLinks: true,
      maxFilesize: 1000000000,

      // Step 1: request an empty blob from the server
      accept: (file, done) => {
        if (file.size == 0) {
          return done("File is empty (0 B).");
        }

        FileChecksum.create(file, (error, checksum) => {
          if (error) {
            return done(error);
          }

          const blobRecord = new BlobRecord(
            file,
            checksum,
            fileInput.dataset.directUploadUrl
          );
          blobRecord.create((error) => {
            if (error) {
              return done(error);
            } else {
              file.blobRecord = blobRecord;
              return done();
            }
          });
        });
      },
    });

    dropzone.on("sending", (file, xhr, data) => {
      xhr.responseType = "text";

      const { headers } = file.blobRecord.directUploadData;

      for (const key in headers) {
        xhr.setRequestHeader(key, headers[key]);
      }
    });

    dropzone.origSubmitRequest = dropzone.submitRequest;
    dropzone.submitRequest = function (xhr, _formData, files) {
      dropzone.origSubmitRequest(xhr, files[0].slice());
    };

    dropzone.on("success", (file) => {
      const hiddenFileField = document.createElement("input");
      hiddenFileField.type = "hidden";
      hiddenFileField.name = fileInput.name;
      hiddenFileField.value = file.blobRecord.attributes.signed_id;

      file.previewElement.appendChild(hiddenFileField);

      if (file.fullPath) {
        const hiddenPathField = document.createElement("input");
        hiddenPathField.type = "hidden";
        hiddenPathField.name =
          "digital_cinema_package[append_paths][" +
          file.blobRecord.attributes.key +
          "]";
        hiddenPathField.value = file.fullPath;

        file.previewElement.appendChild(hiddenPathField);
      }
    });

    dropzone.on("queuecomplete", () => {
      root.submit();
    });

    dropzoneContainer.querySelectorAll(".remove-existing").forEach((el) => {
      el.addEventListener("click", (e) => {
        e.preventDefault();
        el.parentNode.remove();
      });
    });
  }
}
